import { safeParseURL } from '@dte/otw/azure-functions/src/common/util/network/url/getURL';
import { normalizeValue } from '@dte/otw/azure-functions/src/common/util/strings/normalizeValue';
import { useMemo } from 'react';
import { joinUrlPaths } from '../../../app/(client)/(authenticated)/orders/(orderId)/reportedCalls/getPathSection';
import { useSubscriptionKey } from '../../../components/subscriptionKey/useSubscriptionKey';

export function useAuthenticatedUrl(path: string): string {
	const [subscriptionKey] = useSubscriptionKey();

	const url = useMemo(() => {
		if (!subscriptionKey) {
			return undefined;
		}
		const normalizedPath = normalizeValue(path);
		if (!normalizedPath) {
			return undefined;
		}

		const apiHost = process.env.NEXT_PUBLIC_API_BASE;
		const newUrl = joinUrlPaths([apiHost, normalizedPath]);
		return safeParseURL(newUrl);
	}, [subscriptionKey, path]);

	return url;
}
