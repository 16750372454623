import type { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { minutesToMilliseconds, secondsToMilliseconds } from 'date-fns';
import { useCallback } from 'react';
import useSWR, { type SWRConfiguration } from 'swr';
import { useAppInsights } from '../../../components/AppInsightsWrapper/AppInsightsWrapper';
import { InvalidSubscriptionKey, useSubscriptionKey } from '../../../components/subscriptionKey/useSubscriptionKey';
import { useGetToken } from '../../../contexts/TokenContext/useToken';
import { logError } from '../logging/writeLog';
import { fetchWithRetry } from '../network/fetch/fetchWithRetry';
import { BadSubscriptionKeyError } from './BadSubscriptionKeyError';
import { authenticatedFetch } from './authenticatedFetch';
import { getResponseBody } from './getResponseBody';
import { useAuthenticatedUrl } from './useAuthenticatedUrl';

const swrOptions: SWRConfiguration = {
	// Don't try to grab again on focus for at least 5 minutes
	focusThrottleInterval: minutesToMilliseconds(5),
	// If multiple requests come in within the same 15 seconds, make sure to group them together
	dedupingInterval: secondsToMilliseconds(15),
	// How long to trigger slow loading indicator
	loadingTimeout: secondsToMilliseconds(3),

	suspense: false,
	fallbackData: undefined,
	// TODO: maybe we can use suspense with React 19?
};

// Make sure the URL is a string so we don't trigger unnecessary re-renders
export function useAuthenticatedJsonGet<T>(url: string): [T, boolean, Error, () => Promise<T>] {
	const appInsights = useAppInsights();

	const [subscriptionKey, setSubcriptionKey] = useSubscriptionKey();
	const validURL = useAuthenticatedUrl(url);
	const getToken = useGetToken();

	const fetcher = useCallback(async () => {
		try {
			const response = await authenticatedFetch<T>(appInsights, subscriptionKey, getToken, validURL);
			return response as T;
		} catch (error) {
			// Flag if the key is bad
			if (error instanceof BadSubscriptionKeyError) {
				setSubcriptionKey(InvalidSubscriptionKey);
			}

			logError(appInsights, 'Unable to fetch via GET: ', { url: validURL });

			// Rethrow error so that SWR can pass it on to clients
			throw error;
		}
	}, [subscriptionKey, setSubcriptionKey, appInsights, getToken, validURL]);

	const { data, mutate, error, isLoading } = useSWR<T>(validURL, fetcher, swrOptions);

	return [data, isLoading, error as Error, mutate];
}

export function useFetchCached<T>(data: T, mutate: () => Promise<T>) {
	const fetchCached = useCallback(
		async (shouldRefetch?: (value: T) => boolean) => {
			// See if we need to refetch
			let refetch = false;
			if (data === undefined) {
				refetch = true;
			} else if (shouldRefetch) {
				refetch = shouldRefetch?.(data);
			}

			if (!refetch) {
				return data;
			}
			return mutate();
		},
		[data, mutate],
	);

	return fetchCached;
}

async function handleResponse<T>(appInsights: ReactPlugin, response: Response) {
	if (!response) {
		logError(appInsights, 'Error: empty response');
		return undefined;
	}

	const responseBody = await getResponseBody<T>(appInsights, response);
	if (response.ok) {
		return responseBody;
	}

	// TODO: need better error handling for unauthenticated requests
	throw new Error('Error getting response');
}

// Make sure the URL is a string so we don't trigger unnecessary re-renders
export function useJsonGet<T>(validURL: string, requestInit: RequestInit): [T, boolean, Error, () => Promise<T>] {
	const appInsights = useAppInsights();

	const fetcher = useCallback(async () => {
		const response = await fetchWithRetry(validURL, requestInit);
		return handleResponse<T>(appInsights, response);
	}, [appInsights, validURL, requestInit]);

	const { data, mutate, error, isLoading } = useSWR<T>(validURL, fetcher, swrOptions);

	return [data, isLoading, error as Error, mutate];
}
