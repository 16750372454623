'use client';

import type { ReactNode } from 'react';

export interface ContentWithDefaultProps {
	content?: ReactNode;
	defaultContent?: ReactNode;
}

/**
 * Display something if defined, or display a fallback
 *
 * @param props
 * @returns
 */
export default function ContentWithDefault(props: ContentWithDefaultProps): ReactNode {
	let content = props.content;

	// Return default content
	if (content === undefined) {
		content = props.defaultContent;
	}

	// Make sure we return null if nothing is defined
	if (content === undefined) {
		content = null;
	}

	return content;
}
