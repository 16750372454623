'use client';

import { type PropsWithChildren, type ReactNode, useCallback, useEffect, useMemo } from 'react';
import { getRequestInit } from '../../../../../common/util/services/authenticatedFetch';
import { useAuthenticatedJsonGet, useJsonGet } from '../../../../../common/util/services/useAuthenticatedJsonGet';
import { Spinner } from '../../../../../components/Spinner/Spinner';
import CommonError from '../../../../../components/error/page';
import LoadingContent from '../loading/LoadingContent';

export interface FetchContentProps extends PropsWithChildren {
	url?: string;
	setData?: (data: unknown) => void;
	setLoading?: (loading: boolean) => void;
	loadingContent?: ReactNode;
	noDataContent?: ReactNode;
	hasData?: boolean;
}

interface DataLoadingProps {
	message?: string;
}

function DataLoadingError(props: DataLoadingProps): ReactNode {
	const message = props.message || 'Error loading data, click to retry';
	return <>{message}</>;
}

// TODO: combined shared logic for these components
export default function AuthenticatedJsonContent(props: FetchContentProps): ReactNode {
	const url = props.url;

	const parentSetData = props.setData;
	const setData = useCallback(
		(newData: unknown) => {
			if (parentSetData) {
				parentSetData(newData);
			}
		},
		[parentSetData],
	);

	const parentSetLoading = props.setLoading;
	const setLoading = useCallback(
		(newLoading: boolean) => {
			if (parentSetLoading) {
				parentSetLoading(newLoading);
			}
		},
		[parentSetLoading],
	);

	const [data, loading, error, mutate] = useAuthenticatedJsonGet(url);

	useEffect(() => {
		setLoading(loading);
	}, [loading, setLoading]);

	useEffect(() => {
		setData(data);
	}, [data, setData]);

	if (error) {
		return <CommonError error={error} reset={mutate} tooltipContent={<DataLoadingError />} />;
	}

	let loadingContent = props.loadingContent;
	if (loadingContent === undefined) {
		loadingContent = <Spinner />;
	}

	return (
		<LoadingContent
			loading={loading}
			data={data}
			noDataContent={props.noDataContent}
			hasData={props.hasData}
			loadingContent={loadingContent}
		>
			{props.children}
		</LoadingContent>
	);
}

export function FetchJsonContent(props: FetchContentProps): ReactNode {
	const url = props.url;

	const parentSetData = props.setData;
	const setData = useCallback(
		(newData: unknown) => {
			if (parentSetData) {
				parentSetData(newData);
			}
		},
		[parentSetData],
	);

	const parentSetLoading = props.setLoading;
	const setLoading = useCallback(
		(newLoading: boolean) => {
			if (parentSetLoading) {
				parentSetLoading(newLoading);
			}
		},
		[parentSetLoading],
	);

	const requestInit = useMemo(() => {
		return getRequestInit();
	}, []);
	const [data, loading, error, mutate] = useJsonGet(url, requestInit);

	useEffect(() => {
		setLoading(loading);
	}, [loading, setLoading]);

	useEffect(() => {
		setData(data);
	}, [data, setData]);

	if (error) {
		return <CommonError error={error} reset={mutate} tooltipContent={<DataLoadingError />} />;
	}

	let loadingContent = props.loadingContent;
	if (loadingContent === undefined) {
		loadingContent = <Spinner />;
	}

	return (
		<LoadingContent
			loading={loading}
			data={data}
			noDataContent={props.noDataContent}
			hasData={props.hasData}
			loadingContent={loadingContent}
		>
			{props.children}
		</LoadingContent>
	);
}
