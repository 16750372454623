import { useCallback } from 'react';
import { getGoodToken } from './isGoodToken';
import { useAuthenticationResult } from './useAuthenticationResult';

export function useGetToken(): () => Promise<string> {
	const [authenticationResult, mutate] = useAuthenticationResult();

	// Fetch a new token
	const fetchToken = useCallback(async () => {
		// We already have a token
		const token = getGoodToken(authenticationResult);
		if (token) {
			return token;
		}

		// Token is expired or missing, need to get a new one
		const newResult = await mutate();
		return getGoodToken(newResult);
	}, [mutate, authenticationResult]);

	return fetchToken;
}
