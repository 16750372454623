import { filterEmpty } from '@dte/otw/azure-functions/src/common/util/lists/filterEmpty';
import { isEmpty } from '@dte/otw/azure-functions/src/common/util/objects/isEmpty';

export function joinUrlPaths(paths: string[]) {
	let filteredPaths = filterEmpty(paths);
	filteredPaths = filteredPaths.map((path) => path.replace(/^\/|\/$/g, ''));

	if (isEmpty(filteredPaths)) {
		return undefined;
	}

	return filteredPaths.join('/');
}
