export class AuthenticationError extends Error {
	public code: string;

	constructor(code: string, message: string, cause?: Error) {
		super(message, { cause: cause });
		this.code = code ?? 'ERROR_AUTHENTICATION';
	}
}

export class BadSubscriptionKeyError extends AuthenticationError {
	constructor(code: string, message: string, cause?: Error) {
		super(code, message, cause);
		this.code = code ?? 'ERROR_BAD_SUBSCRIPTION_KEY';
	}
}

export class UnauthorizedError extends AuthenticationError {
	constructor(message: string, cause?: Error) {
		super('ERROR_UNAUTHORIZED', message, cause);
	}
}

export class ForbiddenError extends AuthenticationError {
	constructor(message: string, cause?: Error) {
		super('ERROR_FORBIDDEN', message, cause);
	}
}
